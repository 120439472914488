<template>
  <Fieldset>
    <template #legend>
      <i class="pi pi-discord" style="fontsize: 2rem"></i>
      เฟส 1 - แบบจำลองการประเมินความสามารถในการทำงาน
    </template>
    <form class="card" @submit.prevent="processML" id="validity">
      <div class="p-fluid p-formgrid p-grid">
        <!-- career -->
        <div class="p-field p-col-12 p-md-12">
          <div class="p-formgroup-inline">
            <label><strong>อาชีพที่ต้องการทำในอนาคต</strong></label
            >&nbsp;&nbsp;
            <div
              class="p-field-checkbox"
              v-for="(career, value) in careers"
              :key="value"
            >
              <RadioButton
                :id="value"
                name="career_value"
                :value="value"
                v-model="careerValue"
              />
              <label :for="value">{{ career.text }}</label>
            </div>
          </div>
        </div>

        <!-- bmi -->
        <div class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">1) ส่วนสูง</label>
          <InputText
            v-model="criteria['height']"
            type="number"
            placeholder="ซ.ม."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">น้ำหนัก</label>
          <InputText
            v-model="criteria['weight']"
            type="number"
            placeholder="ก.ก."
            :min="0"
            :max="999"
            :required="true"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label class="p-col-fixed">ดัชนีมวลกาย (BMI)</label>
          <InputText
            v-model="criteria['bmi']"
            type="number"
            :readonly="true"
            :required="true"
            style="background-color: #dcdcdc"
          />
        </div>

        <!-- waistline_inch -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">2) รอบเอว (นิ้ว)</label>
          <div class="p-inputgroup" style="width: 72%">
            <InputText
              v-model="criteria['waistline_inch']"
              type="number"
              placeholder="นิ้ว"
              :min="0"
              :max="999"
              :required="true"
            />
            &nbsp;&nbsp;<label>นิ้ว</label>
          </div>
        </div>

        <!-- income_total -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed">3) รายได้ของครอบครัวเฉลี่ยต่อเดือน</label>
          <InputNumber
            v-model="criteria['income_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 100,000 บาท)</label>
        </div>

        <!-- income_main_total -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >4) รายได้หลักจากการทำงานของท่าน เฉลี่ยรวมต่อเดือนที่ผ่านมา</label
          >
          <InputNumber
            v-model="criteria['income_main_total']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายได้ 0 – 60,000 บาท)</label>
        </div>

        <!-- expend_avg -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >5) รายจ่ายครอบครัว เฉลี่ยรวมต่อเดือน</label
          >
          <InputNumber
            v-model="criteria['expend_avg']"
            placeholder="บาท"
            :min="0"
            :required="true"
            style="width: 65%"
            mode="decimal"
            :minFractionDigits="2"
          />
          &nbsp;<label>บาท</label>
          <label>(ช่วงรายจ่าย 0 – 50,000 บาท)</label>
        </div>

        <!-- pain_score -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >6) ประเมินอาการปวดบริเวณร่างกาย
            <strong>(คะแนนน้อยคือสุขภาพดี)</strong></label
          >

          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['pain_score']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 65%"
              :should="90"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('pain_score', true)"
            />
            &nbsp;&nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนประเมินอาการปวด อยู่ระหว่าง 0 - 90 คะแนน)</label>
        </div>

        <!-- health_sum -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >7) ผลรวมปัญหาสุขภาพและการใช้ร่างกาย
            <strong>( 0 คะแนนคือไม่มีปัญหา)</strong></label
          >
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['health_sum']"
              type="number"
              :min="0"
              :max="99"
              placeholder="คะแนน"
              :required="true"
              style="width: 65%"
              :should="16"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('health_sum', true)"
            />
            &nbsp;&nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 16 คะแนน)</label>
        </div>

        <!-- sum_tmse -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >8) คะแนนสมรรถภาพสมอง (TMSE)
            <strong>(คะแนนมากคือสมรรถภาพสมองดี)</strong></label
          >
          <br /><br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_tmse']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 75%"
              :should="30"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_tmse', true)"
            />
            &nbsp;&nbsp;<label style="float: right">คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 – 30 คะแนน)</label>
        </div>

        <!-- sum_sts -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >9) คะแนนการประเมินความเครียด (ST5)
            <strong>(คะแนนมากคือมีความเครียดมาก)</strong></label
          >
          <br />
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['sum_sts']"
              type="number"
              placeholder="คะแนน"
              :min="0"
              :max="99"
              :required="true"
              style="width: 65%"
              :should="15"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('sum_sts', true)"
            />
            &nbsp;<label>คะแนน</label>
          </div>
          <label>(คะแนนอยู่ในช่วง 0 - 15 คะแนน)</label>
        </div>

        <!-- disease_total -->
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed"
            >10) ระบุการเจ็บป่วยหรือโรคประจำตัวของท่าน</label
          >
          <div class="p-col-fixed">
            <strong>(โรคที่แพทย์เป็นผู้วินิจฉัยและให้การรักษา)</strong>
          </div>
          <div class="p-inputgroup" style="width: 77%">
            <InputText
              v-model="criteria['disease_total']"
              type="number"
              placeholder="โรค"
              :min="0"
              :max="12"
              :required="true"
              style="width: 65%"
            />
            <Button
              icon="pi pi-search"
              class="p-button-primary"
              @click="() => openModalHelper('disease_total', true)"
            />
            &nbsp;<label>โรค</label>
          </div>
          <label>(ข้อมูลอยู่ในช่วง 0 – 12 โรค)</label>
        </div>
      </div>
      <div class="p-d-flex p-jc-center">
        <Button
          type="submit"
          label="วิเคราะห์ผล"
          :icon="['pi pi-spinner', isLoading ? 'pi-spin' : '']"
          iconPos="left"
        />
      </div>
      <Divider align="left" type="solid">
        <b>อธิบายเพิ่มเติม</b>
      </Divider>
      <ul>
        <li>
          เปอร์เซ็นต์ความแม่นยำในการทำนายความสามารถในการทำงาน อยู่ที่
          {{ mapCareerPercentLabel }}
          <br />
          <strong v-if="careerValue != 'career_all'"
            >(
            <span style="color: red"
              >กรณี
              {{ mapCareerLabel }}
              ข้อมูลในการวิเคราะห์เพื่อสร้างแบบจำลองมีจำนวนน้อย
              <br />มีผลต่อความคลาดเคลื่อนในการประเมินความสามารถในการทำงานผู้สุงอายุ</span
            >
            )</strong
          >
        </li>
        <li>ผลวิเคราะห์ความสามารถในการทำงาน มี 3 ระดับ</li>
        <ol>
          <li style="color: red"><strong>มีปัญหาในการทำงาน</strong></li>
          <li style="color: green"><strong>สามารถทำงานได้</strong><br /></li>
          <li style="color: purple">
            <strong>สามารถทำงานได้ดี</strong>
          </li>
        </ol>
      </ul>
    </form>
  </Fieldset>
  <Dialog
    :header="'ผลการวิเคราะห์ความสามารถในการทำงาน' + mapCareerLabel"
    v-model:visible="isModal"
    :style="{ width: '50vw' }"
  >
    <p class="p-d-flex p-jc-center" v-html="mapPredictScoreHtml"></p>
    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        @click="() => (isModal = false)"
        class="p-button-text"
      />
    </template>
  </Dialog>
  <ModalDiseaseTotal />
  <ModalIncomeSourceScore />
  <ModalSumSts />
  <ModalSumTmse />
  <ModalHealthSum />
  <ModalPainScore />
</template>

<script>
import axios from 'axios'
import { initCustomValidity } from '@/HTMLElement'
import { mixins } from '../Mixins'

import ModalIncomeSourceScore from './ModalIncomeSourceScore.vue'
import ModalSumSts from './ModalSumSts.vue'
import ModalDiseaseTotal from './ModalDiseaseTotal'
import ModalSumTmse from './ModalSumTmse'
import ModalHealthSum from './ModalHealthSum'
import ModalPainScore from './ModalPainScore'

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      criteria: {
        bmi: null,
        waistline_inch: null,
        income_total: null,
        income_main_total: null,
        expend_avg: null,
        pain_score: null,
        health_sum: null,
        sum_tmse: null,
        sum_sts: null,
        disease_total: null
      },
      careerValue: 'career_all',
      careers: {
        career_all: { text: 'ไม่ระบุ', percent: '76' },
        career_need_1: { text: 'ค้าขาย', percent: '76' },
        career_need_2: { text: 'ทำไร่/ทำสวน', percent: '77' },
        career_need_3: { text: 'กรีดยาง', percent: '90' },
        career_need_4: { text: 'ทำสวนผลไม้', percent: '100' },
        career_need_5: { text: 'ทำนา', percent: '90' }
        //career_need_6: 'ไม่เลือก 7 อาชีพ'
      }
    }
  },
  computed: {
    mapPredictScoreHtml() {
      const { text = '-', color } = this.grade[this.score] || { color: 'black' }
      return `<h1 style="color:${color};font-size:50px;font-weight: bold;"> ${text}</h1>`
    },
    mapCareerLabel() {
      if (this.careerValue == 'career_all') {
        return ': '
      } else {
        const { text } = this.careers[this.careerValue]
        return ` อาชีพ: ${text}`
      }
    },
    mapCareerPercentLabel() {
      const { percent } = this.careers[this.careerValue]
      console.log('percent ::==', percent)
      return `${percent}%`
    }
  },
  watch: {
    'criteria.weight'(newVal, oldVal) {
      console.log('weight newVal ::==', newVal)
      console.log('weight oldVal ::==', oldVal)
      this.calBMI()
    },
    'criteria.height'(newVal, oldVal) {
      console.log('height newVal ::==', newVal)
      console.log('height oldVal ::==', oldVal)
      this.calBMI()
    },
    '$store.state.prediction.labelsValue': {
      handler(newVal, oldVal) {
        console.log('newVal ::==', JSON.stringify(newVal))
        console.log('oldVal ::==', oldVal)
        this.criteria = { ...this.criteria, ...newVal }
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log('initCustomValidity validate thai message !')
      initCustomValidity('validity')
    })
  },
  methods: {
    openModalHelper(modalName, isOpen) {
      //console.log('modalName ::==', modalName)
      this.$store.commit('prediction/setModal', { modalName, isOpen })
    },
    async calBMI() {
      // weight/((height/100)*(height/100));
      const { weight = 0, height = 0 } = this.criteria
      // Math.round(num * 100) / 100
      this.criteria.bmi = (
        Math.round((weight / ((height / 100) * (height / 100))) * 100) / 100
      ).toFixed(0)
    },
    async processML() {
      const { VUE_APP_API_ENDPOINT } = process.env
      let payload = {
        labels: this.criteria,
        loop: 100,
        area_id: 999,
        career_value: this.careerValue,
        route : 'predict1'
      }
      this.message = null
      this.isLoading = true

      //payload['diagnosis_drug_count'] = null

      //alert('payload ::==' + JSON.stringify(payload))
      try {
        const { data: result } = await axios({
          url: `${VUE_APP_API_ENDPOINT}/predict/train`, // labels=weight,bmi,waistline&area_id=999&algo=2
          data: payload,
          method: 'POST'
        })
        const { status, data } = result
        if (status.code == '01') {
          alert(
            'เกิดข้อผิดพลาดในระหว่างการวิเคราะห์ผล กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ'
          )
        } else {
          
          var dataJson = JSON.parse(data);
          var score = dataJson.datas.score;
          this.message = JSON.stringify({
            ...data,
            grade: this.grade[score] || 'ดีมาก'
          })
          this.score = score
          this.isModal = true
        }
      } catch (error) {
        console.log('error ::==', error)
        alert(
          'เกิดข้อผิดพลาดในระหว่างการวิเคราะห์ผล กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ'
        )
      }
      this.isLoading = false
    }
  },
  components: {
    ModalDiseaseTotal,
    ModalIncomeSourceScore,
    ModalSumSts,
    ModalSumTmse,
    ModalHealthSum,
    ModalPainScore
  }
}
</script>

<style lang="scss" scoped>
</style>